/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Fade } from "react-reveal";
import { Col, Container, Row } from "reactstrap";
import style from "../../styles/footer.module.css";

const Footer = () => {
  return (
    <>
      <footer className={style.footer}>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={4}>
              <Fade top deley={200}>
                <div className={style.FooterLogo}>
                  <a href="#">
                    <img src="/logo.svg" alt="realloc" width={235} />
                  </a>
                  <p className={style.Address}>
                    B-801 to 804, IT Park, Opp. Gajera International
                    <br />
                    School, Digital Valley (Uttran) Surat, <br /> Gujarat, India
                    - 394101
                  </p>
                </div>
              </Fade>
            </Col>
            <Col xs={6} md={3} lg={2}>
              <Fade top deley={200}>
                <div>
                  <h4 className={style.FooterTitle}>Company</h4>
                  <ul className={style.FooterLinks}>
                    <li>About Us</li>
                    <li>Careers</li>
                    <li>Contact Us</li>
                    <li>Media</li>
                  </ul>
                </div>
              </Fade>
            </Col>
            <Col xs={6} md={3} lg={2} className={style.centerAlign}>
              <Fade top deley={200}>
                <div className={style.CenterdLinks}>
                  <h4 className={style.FooterTitle}>Discover</h4>
                  <ul className={style.FooterLinks}>
                    <li>Our Blog</li>
                    <li>Awards</li>
                  </ul>
                </div>
              </Fade>
            </Col>
            <Col xs={6} md={3} lg={2} className={style.RightAlign}>
              <Fade top deley={200}>
                <div className={style.CenterdLinks}>
                  <h4 className={style.FooterTitle}>Legal</h4>
                  <ul className={style.FooterLinks}>
                    <li>Terms of Use</li>
                    <li>Privacy Policy</li>
                    <li>Cookie Policy</li>
                    <li>Site Map</li>
                  </ul>
                </div>
              </Fade>
            </Col>
            <Col xs={6} md={3} lg={2} className={style.rightAlign}>
              <Fade top deley={200}>
                <div className={style.RightLinks}>
                  <h4 className={style.FooterTitle}>Support</h4>
                  <ul className={style.FooterLinks}>
                    <li>FAQs</li>
                    <li>Help</li>
                    <li>Our license</li>
                  </ul>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className={style.Copyright}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={8}>
              <p className={style.CopyrightText}>
                ©2024 Realloc Infotech PVT LTD. All Right Reserved.
              </p>
              <ul className={style.CopyrightLink}>
                <li>About Us</li>
                <li>Portfolio</li>
                <li>Blog</li>
                <li>Privacy Policy</li>
                <li>Sitemap</li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={4} className={style.footerBottomRight}>
              <div className={style.socialIcon}>
                <p className={style.CopyrightText}>Follow us on</p>
                <div className={style.CopyrightSociaLink}>
                  <img src="/twitter.png" alt="#" />
                  <img src="/facebook.png" alt="#" />
                  <img src="/instagram.png" alt="#" />
                  <img src="/pinterest.png" alt="#" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
