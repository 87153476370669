import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/animate.min.css";
import "./styles/poppins/poppins.css";
import "./App.css";
import OurTechnology from "./component/OurTechnology";
import ContactUs from "./component/Contact";
import GetInTouch from "./component/GetInTouch";
import LayoutContent from "./component/LayoutContent";
import MainSlider from "./component/MainSlider";
import OurBlogs from "./component/OurBlogs";
import OurWorks from "./component/OurWorks";
import Services from "./component/Services";
import Solutions from "./component/Solutions";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsOfServices from "./component/TermsOfServices";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutContent>
              <MainSlider />
              <Services />
              <Solutions />
              <OurWorks />
              <OurTechnology />
              <ContactUs />
              <OurBlogs />
              <GetInTouch />
            </LayoutContent>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/termsofservices" element={<TermsOfServices />} />
      </Routes>
    </Router>
  );
}

export default App;
