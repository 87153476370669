import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Bounce, LightSpeed, Zoom } from 'react-reveal';
import style from '../styles/OurWork.module.css'
import Slider from "react-slick";


const OurWorks = () => {

  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    // arrows:false,
    dotsClass: style.customDot,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: dots => {
      return (
        <div>
          <ul> {dots} </ul>
        </div>
      )
    },

    customPaging: i => (
      <span>
        0{i + 1}
      </span>
    ),
    afterChange: current => setActiveSlide(current)
  };

  var slider = [
    {
      name: 'App Development',
      Title: 'Codicis',
      subTitle: 'Gathered by gravity take root and flourish of brilliant syntheses.',
      image: '/solution/image-1.png',
    },
    {
      name: 'Web Development',
      Title: 'Paradox',
      subTitle: 'Elevate the growth process of your business with designed and developed web application.',
      image: '/solution/image-2.png',
    },
    {
      name: 'App Development',
      Title: 'BodyfitX',
      subTitle: 'We provide a range of mobile application development services including building cross-platform apps using Flutter and React Native.',
      image: '/solution/image-3.png',
    },
    {
      name: 'Blockchain App',
      Title: 'NFTbox',
      subTitle: "we'll help you build the best NFT app for you, including features like view digital assets, art, music, history, offers.",
      image: '/solution/image-4.png',
    },
    {
      name: 'IOS App Development',
      Title: 'MyPay',
      subTitle: 'Design and build iOS applications for Apple mobile platforms using object-oriented development principles in Swift or Objective-C.',
      image: '/solution/image-5.png',
    }
  ]

  function SampleNextArrow(props) {
    const { className, style: css, onClick } = props;
    return (
      <div
        className={`${className} ${style.RightArrow}`}
        style={{ ...css }}
        onClick={onClick}
      >
        <img alt='' src='/right-arrow.png' />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style: css, onClick } = props;
    return (
      <div
        className={`${className} ${style.LeftArrow}`}
        style={{ ...css }}
        onClick={onClick}
      >
        <img alt='' src='/left-arrow.png' />
      </div>
    );
  }

  return (
    <section className={style.OurWorks} id='our-work'>
      <Container>
        <Zoom cascade>
          <h4 className={style.Title}>OUR WORK</h4>
        </Zoom>
        <Row>
          <Col xs={12} sm={8} md={8}>
            <div className={style.counter}>
              <LightSpeed left cascade><h4>60+</h4></LightSpeed>
              <span>Project Deliverd</span>
            </div>
            <div className={style.counter}>
              <LightSpeed left cascade><h4>50+</h4></LightSpeed>
              <span>Happy Clients</span>
            </div>
          </Col>
          <Col xs={12} sm={4} md={4} className={style.viewallBtn}>
            <h4>VIEWALL</h4>
          </Col>
        </Row>
        <div className={style.SliderWrap}>
          <Bounce cascade spy={activeSlide} deley={400}><div className={style.BgText}>Work</div></Bounce>
          <Slider {...settings}>
            {slider.map((sl) => {
              return (
                <div key={sl.Title}>
                  <div className={style.item}>
                    <div className={style.sliderTitleContent}>
                      <span>{sl.name}</span>
                      <h4>{sl.Title}</h4>
                      <p>{sl.subTitle}</p>
                      {/* <div className={style.CaseStudyImg}><img src='/case-study.png' alt='case study' /></div> */}
                    </div>
                    <div className={style.SliderImage}>
                      <img src={sl.image} alt={sl.Title} />
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
          <div className={style.SlideCount}>{activeSlide + 1}&nbsp;/&nbsp;5</div>
        </div>
      </Container>
    </section>
  )
}

export default OurWorks