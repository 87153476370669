import React from 'react'
import Header from './Common/Header'
import Footer from './Common/Footer'


const LayoutContent = (props) => {
  
  return (
    <>
      <div className='liner-bg'>
        <span className='line-1'></span>
        <span className='line-2'></span>
        <span className='line-3'></span>
        <span className='line-4'></span>
        <span className='line-5'></span>
      </div>
      <Header/>
        <main className='layout-content'>{props.children}</main>
      <Footer/>
    </>
  )
}

export default LayoutContent