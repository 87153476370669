import React from "react";
import style from "../styles/privacyPolicy.module.css";

function TermsOfServices() {
  return (
    <div className={style.servicesContainer}>
      <div className={style.logoContainer}>
        <img src="/logo.svg" alt="realloc" width={235} />
      </div>
      <h2 className={style.mainTitle}>
        <b>Terms of Service</b>
      </h2>
      <div class="col-md-12 col-xs-10 col-xs-offset-1"> </div>
      <p className={style.containText}>
        Please read these Terms of Use carefully before using the Application
        which developed by Realloc Infotech PVT LTD. These Terms apply to all
        users and others who use the App. By using the App you agree to be bound
        by these Terms. If you disagree the terms then you may not use the App.
        You agree that you will not use the Application Service for any illegal
        purposes, including but not limited to the pirating or illegal
        distribution of software. You agree that you will not attempt to access
        areas and information that you are not authorized to access.
      </p>
      <h2 className={style.subHeader}>
        <b>Limited Liability</b>
      </h2>
      <p className={style.containText}>
        You accept that; Realloc Infotech PVT LTD do not have any sort of
        liability what so ever for anything that may arise from the use of our
        apps.
      </p>
      <h2 className={style.subHeader}>
        <b>Termination</b>
      </h2>
      <p>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms.
      </p>
      <h2 className={style.subHeader}>
        <b>Redirects to other Web Sites</b>
      </h2>
      <p className={style.containText}>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by Realloc Infotech PVT LTD. Realloc
        Infotech PVT LTD has no control over, and assumes no responsibility for,
        the content, privacy policies, or practices of any third party web sites
        or services. You further acknowledge and agree that Realloc Infotech PVT
        LTD shall not be responsible or liable, directly or indirectly, for any
        damage or loss caused or alleged to be caused by or in connection with
        use of or reliance on any such content, goods or services available on
        or through any such web sites or services. We strongly advise you to
        read the terms and conditions and privacy policies of any third-party
        web sites or services that you visit.
      </p>
      <h2 className={style.subHeader}>
        <b>Law</b>
      </h2>
      <p className={style.containText}>
        These Terms shall be governed and construed in accordance with the laws
        of your country, without regard to its conflict of law provisions. Our
        failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have between us regarding the Service.
      </p>
      <h2 className={style.subHeader}>
        <b>Changes</b>
      </h2>
      <p className={style.containText}>
        We also reserve the right at any time to modify or discontinue the
        Service, temporarily or permanently, with or without notice to you. If
        you do not agree to the new terms, please stop using the Service.
      </p>
      <h2 className={style.subHeader}>
        <b>Support</b>
      </h2>
      <p className={style.containText}>
        We always try to fix the Application issue but as some of the our apps
        depends on phone hardware So we might We might not be able to solve the
        issue is related to phone Hardware OR if you upgrade to a new Android
        Phone OR You Change your phone to different one, In this case you change
        your device your old phone application will be not be able to transfer
        old Phone Data/Settings to your new Phone automatically you can try with
        share feature of the app manually.
      </p>
      <h2 className={style.subHeader}>
        <b>Contact Us</b>
      </h2>
      <p className={style.containText}>
        If you have any questions about these Terms, please contact us via
        Email:
        <a href="mailto:info@reallocinfotech.com">info@reallocinfotech.com</a>.
      </p>
      <br />
      <br />
    </div>
  );
}

export default TermsOfServices;
