import React from "react";
import style from "../styles/privacyPolicy.module.css";

function PrivacyPolicy() {
  return (
    <div className={style.mainWrapper}>
      <img src="/logo.svg" alt="realloc" width={235} />
      <div data-new-gr-c-s-check-loaded="14.1168.0" data-gr-ext-installed="">
        <div id="wrap">
          <div id="content">
            <div id="left">
              <h2 className={style.mainTitle}>PRIVACY &amp; POLICY</h2>
              <p className={style.containText}>
                At <b>Realloc Infotech PVT LTD</b> we recognize that privacy is
                significant. This Privacy Policy applies to all apps (Android
                &amp; IOS) under the publisher name of{" "}
                <b>Realloc Infotech PVT LTD</b>. We has created this Policy to
                explain our privacy practices so you can understand what
                information about you is collected, used and disclosed. We
                collect information from you in order to provide corresponding
                service and better user experience. With your consent of this
                Privacy Policy, your usage, statistics, input while using our
                apps would be collected.
              </p>

              <h2 className={style.subHeader}>
                INFORMATION COLLECTION &amp; USE
              </h2>
              <ul className={style.containText}>
                <li>
                  Generally. We may collect personal information from our users
                  in order to provide you with a personalized, useful and
                  efficient experience. The categories of information we collect
                  can include: Social network's personal information and
                  device's gallery pictures.
                </li>
                <li>
                  Non-personal identification. We may collect non-personal
                  identification information about installed applications,
                  application usage information and device information. The
                  information you give us, for example, when you give us your
                  opinions to our application and services via our feedback
                  channel, such as your email address, and names.
                </li>
              </ul>

              <h2 className={style.subHeader}>EU USER CONSENT POLICY</h2>
              <p className={style.containText}>
                We include certain disclosures to users in the European Economic
                Area (EEA) &amp; obtain their consent to use cookies or other
                local storage, where legally required, and to use personal data
                (such as AdID) to serve ads. This policy reflects the
                requirements of the EU ePrivacy Directive and the General Data
                Protection Regulation (GDPR).
              </p>
              <p className={style.containText}>
                We ask about permission of users which are in{" "}
                <b>EEA(European Economic Area)</b> to display ads like{" "}
                <b>PERSONALIZE/NON-PERSONALIZE</b>.
              </p>
              <p className={style.containText}>
                Learn about EU User Consent Policy :
              </p>
              <ul className={style.containText}>
                <li>
                  <a href="https://www.google.com/about/company/user-consent-policy.html">
                    EU User Consent Policy
                  </a>
                </li>
              </ul>

              <h2 className={style.subHeader}>
                PRIVACY POLICY OF THIRD PARTY SERVICE PROVIDERS USED BY THE APP
              </h2>
              <p className={style.containText}>
                For a better experience, while using our Service, I may require
                you to provide us with certain personally identifiable
                information. The information that I request is retained on your
                device and is not collected by me in any way
              </p>
              <p className={style.containText}>
                The app does use third party services that may collect
                information used to identify you.
              </p>
              <ul className={style.containText}>
                <li>
                  <a href="https://www.google.com/policies/privacy/">
                    Google Play Services
                  </a>
                </li>
                <li>
                  <a href="https://support.google.com/admob/answer/6128543?hl=en">
                    AdMob
                  </a>
                </li>
                <li>
                  <a href="https://firebase.google.com/policies/analytics">
                    Firebase Analytics
                  </a>
                </li>
              </ul>

              <h2 className={style.subHeader}>
                HOW WE USE COLLECTED INFORMATION
              </h2>
              <p className={style.containText}>
                We may use your information only for serving you better as
                below:
              </p>
              <ul className={style.containText}>
                <li>To personalize user experience</li>
                <li>
                  To help develop our service- We may use Non- Personal
                  Information to provide, maintain, improve and develop our
                  services;
                </li>
                <li>
                  We may collect your non-personal information and use them for
                  market and promotion purpose, for example, we may promote or
                  recommend more relevant apps to you.
                </li>
              </ul>
              <p className={style.containText}>
                We use this information to operate, maintain, and provide to you
                the features and functionality of our Apps, as well as to
                communicate directly with you, such as to send you email
                messages and push notifications. We may also send you
                Service-related emails or messages (e.g., account verification,
                order confirmations, change or updates to features of our apps,
                technical and security notices).
              </p>

              <h2 className={style.subHeader}>
                HOW YOUR INFORMATION MAY BE DISCLOSED
              </h2>
              <ul className={style.containText}>
                <li>
                  Personal Information. We do not store Personal Information and
                  therefore we do not disclose your Personal Information.
                </li>
                <li>
                  Non-Personal Information. We do not combine Non-Personal
                  Information with Personal Information (such as combining your
                  name with your unique User Device number).
                </li>
                <li>
                  We may disclose your non-personal information for promotion
                  purpose to our trusted partners who shall comply with this
                  privacy policy and the relevant privacy laws.
                </li>
              </ul>

              <h2 className={style.subHeader}>THIRD- PARTY SITES</h2>
              <p className={style.containText}>
                In general, the Applications, the Services and the Site access
                third party information (such as your Facebook account
                information) through application interfaces. We may provide
                links to third-party Web sites, such as Facebook, as a service
                to our Users. The Site may also carry advertisements from other
                companies. When you click on links of third parties in an
                Application, the Services or the Site, you may leave the
                Application, the Services or the Site. Some of these third party
                sites may be co-branded with our name/logo or our affiliated
                entity's name/logo, even though they are not operated or
                maintained by us. This Policy does not address, and we are not
                responsible for, the privacy practices of Web sites operated by
                third parties, whether they are linked to or otherwise
                accessible from an Application, the Services or the Site. The
                inclusion of a link or accessibility of third party Websites
                does not imply endorsement of such third party Website by us
              </p>

              <h2 className={style.subHeader}>
                USERS WITH WHOM YOU SHARE YOUR INFORMATION
              </h2>
              <p className={style.containText}>
                We cannot control the actions of other Users with whom you share
                your information. We cannot, and do not, control the information
                you share with other Users using an Application, the Services or
                the Site (including via Forums) or how such other Users will use
                or share such information. We are not responsible for third
                party circumvention of our security measures.
              </p>

              <h2 className={style.subHeader}>SECURITY</h2>
              <p className={style.containText}>
                We are very concerned about safeguarding the confidentiality of
                your information. We do not collect Personal Information, and we
                employ administrative, physical and electronic measures designed
                to protect your Non-Personal Information from unauthorized
                access and use. Please be aware that no security measures that
                we take to protect your information is absolutely guaranteed to
                avoid unauthorized access or use of your Non-Personal
                Information which is impenetrable.
              </p>

              <h2 className={style.subHeader}>SENSITIVE INFORMATION</h2>
              <p className={style.containText}>
                We ask that you not send us, and you not to disclose, any
                sensitive Personal Information (e.g., information related to
                racial or ethnic origin, political opinions, religion or other
                beliefs, health, sexual orientation, criminal background or
                membership in past organizations, including trade union
                memberships) on or through an Application, the Services or the
                Site or otherwise to us.
              </p>

              <h2 className={style.subHeader}>
                CHANGES TO THIS PRIVACY POLICY
              </h2>
              <p className={style.containText}>
                Our Privacy Policy may change from time to time, we will post
                any privacy policy changes on this page, so please review it
                periodically. We may provide you additional forms of notice of
                modifications or updates as appropriate under the circumstances.
              </p>
              <p className={style.containText}>
                If you do not agree to any modifications to this Policy, your
                sole recourse is to immediately stop all use of all
                Applications, the Services and the Site. Your continued use of
                any Application, the Services or the Site following the posting
                of any modifications to this Policy will constitute your
                acceptance of the revised Policy. Please note that none of our
                employees or agents has the authority to vary any of our
                Policies.
              </p>
            </div>
          </div>
        </div>

        <div id="clear"></div>
      </div>
      <grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration>
    </div>
  );
}

export default PrivacyPolicy;
