import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import style from '../styles/Solutions.module.css'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { tabList } from '../utils/constants';


const Solutions = () => {
  const [activeTab, setActiveTab] = useState(1)
  
  return (
    <section className={style.OurServices} id='solution'>
      <Container className={style.solutionContainer}>
        <div className={style.mainTitleWrap}>
          <svg className={style.ServiceTextIcon} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1431 275" width="1480" height="285">
            <path id="Servics" fillRule="evenodd" className={style.serviceText} d="m86.9 273c-48.5 0-85.9-26.6-85.9-69.6h42.6c1.8 19.9 15.7 36 43.3 36 28 0 43.7-15 43.7-35.6 0-58.7-128.9-20.6-128.9-110.1 0-42.6 33.9-69.1 83.5-69.1 46.5 0 79.3 24.4 82.8 66h-44c-1.4-16.4-15.7-31.5-40.9-32.2-23.1-0.7-40.9 10.5-40.9 33.9 0 54.9 128.6 20.6 128.6 109.4 0 36.3-29.7 71.3-83.9 71.3zm213.9 0.7c-55.2 0-96.1-39.2-96.1-99.6 0-60.5 39.1-99.2 96.1-99.2 54.8 0 93.6 38 93.6 94.6 0 6.7-0.4 13-1.4 19.6h-147.1c2.8 31.8 25.5 51 54.9 51 24.4 0 38-11.9 45.4-26.9h42.9c-10.8 33.6-41.2 60.5-88.3 60.5zm-54.5-116.7h106.5c-0.7-29.7-24.1-48.6-53.8-48.6-26.9 0-48.6 18.2-52.7 48.6zm227 113.5h-39.8v-192.5h39.8v28c11.2-19.3 30.4-31.1 57.7-31.1v41.2h-10.1c-29.4 0-47.6 12.2-47.6 53.1zm114.3-192.5l54.5 156.9 54.5-156.9h42.3l-73.4 192.5h-47.5l-73-192.5zm181.3 0h39.8v192.5h-39.8zm-5.3-51c0-14.3 11.2-25.5 25.5-25.5 14 0 25.2 11.2 25.2 25.5 0 14.3-11.2 25.5-25.2 25.5-14.3 0-25.5-11.2-25.5-25.5zm178.6 47.9c47.1 0 77.9 23.4 88.7 63.9h-43c-6.6-18.5-22-30.4-45.7-30.4-32.2 0-53.5 23.7-53.5 65.7 0 42.2 21.3 66 53.5 66 23.7 0 38.4-10.5 45.7-30.4h43c-10.8 37.7-41.6 63.9-88.7 63.9-55.2 0-94.4-39.1-94.4-99.5 0-60.1 39.2-99.2 94.4-99.2zm211.3 198.7c-55.2 0-96-39.1-96-99.5 0-60.5 39.1-99.2 96-99.2 54.9 0 93.7 38 93.7 94.6 0 6.7-0.4 13-1.4 19.6h-147.1c2.8 31.8 25.5 51 54.8 51 24.5 0 38.1-11.9 45.5-26.9h42.9c-10.8 33.5-41.2 60.4-88.4 60.4zm-54.5-116.6h106.6c-0.7-29.7-24.1-48.6-53.8-48.6-26.9 0-48.6 18.2-52.8 48.6zm257.2 116.6c-46.1 0-78.6-27.2-80.4-62.1h41.2c1.4 15.7 16.5 28.6 38.5 28.6 23 0 35.3-9.8 35.3-23.1 0-37.7-111.8-16-111.8-86.6 0-30.7 28.6-55.5 74-55.5 43.7 0 72.3 23.4 74.4 61.8h-39.8c-1.4-16.8-14.3-28.3-36-28.3-21.3 0-32.5 8.7-32.5 21.7 0 38.7 108.7 17.1 110.8 86.6 0 32.5-28.3 56.9-73.7 56.9z" />
          </svg>
          <Zoom cascade>
            <h4 className={style.smallTitle}>Best</h4>
            <h2 className={style.mainTitle}>Our Solutions</h2>
            <p className={style.subTitle}>Here, we make almost every genre of applications. You name it and we build it.</p>
          </Zoom>
        </div>
        <div className={style.tabWrapper}>
          <Row>
            <Col xs={12} sm={3} md={3}>
              <Zoom cascade top>
                <ul className={style.Tabs}>
                  {tabList.map((el, ind) => {
                    return (
                      <li key={ind} onClick={() => setActiveTab(el.id)} className={activeTab === el.id ? style.tabsActive : ''} >{el.name}</li>
                    )
                  })}
                </ul>
              </Zoom>
            </Col>
            <Col xs={12} sm={9} md={9}>
              {tabList.map((el, ind) => {
                return (
                  <Fade key={ind}>
                    <div className={activeTab === el.id ? style.tabContentBox + ' ' + style.tabContentBoxShow : style.tabContentBox}>
                      <div className={style.tabContent}>
                        <Zoom cascade><h4>{el.name}</h4></Zoom>
                        <p>{el.description}</p>
                        <Zoom cascade duration={400}>
                          <ul>
                            {el.featureArr && el.featureArr.map(text => {
                              return <li>{text}</li>
                            })}
                          </ul>
                        </Zoom>
                        <Zoom duration={400}>
                          <div className={style.tabContentImage}>
                            <img src={el.image} alt='0' />
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </Fade>
                )
              })}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Solutions