/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import style from '../styles/OurTechnology.module.css'
import Zoom from 'react-reveal/Zoom';


const OurTechnology = () => {
  return (
    <section className={style.OurTechnology} id='clients'>
      <Container>
        <div className={style.mainTitleWrap} >
          <Zoom cascade left><h4 className={style.smallTitle}>OUR TECHNOLOGY</h4></Zoom>
          <Zoom cascade><div><h2 className={style.mainTitle}>Your Successful, our <br /> reputation</h2></div></Zoom>
        </div>
        <div className={style.BlogBox}>
          <Row>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-1.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>React Js</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-2.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Javascript</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorderMobile}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-3.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Angular</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-4.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Node Js</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-5.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Python</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-6.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Amazon Web Service</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-7.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Flutter</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-8.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Java</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-9.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>React Native</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-10.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>C++</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-11.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Typescript</a>
                  </div>
                </div>
              </Zoom>
            </Col>
            <Col xs={12} sm={6} md={3} className={style.ShapeBorder}>
              <Zoom>
                <div className={style.BoxWrap}>
                  <div className={style.ClientLogos}>
                    <img src='/techlonogy/logo-12.png' alt='#' />
                  </div>
                  <div className={style.ClientHover}>
                    <a href='#'>Android</a>
                  </div>
                </div>
              </Zoom>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default OurTechnology
