import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styles from '../styles/Slider.module.css'
import ScrollAnimation from 'react-animate-on-scroll'
import { Zoom, Fade } from 'react-reveal';
import Jump from 'react-reveal';

const MainSlider = () => {
  return (
    <section className={styles.Mainbanner}>
      <Container className={styles.bannerContainer}>
        <Row className='align-items-center'>
          <Col xs={12} sm={6} md={6}>
            <div className={styles.MainbannerContent}>
              <Fade big>
                <ScrollAnimation animateIn='slideInDown' initiallyVisible>
                  <h2>#1</h2>
                </ScrollAnimation>
                <Zoom cascade>
                  <h3>#1 Top</h3>
                  <h4>Web Development</h4>
                  <h5>Company in India</h5>
                </Zoom>
              </Fade>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} className={styles.mainBannerWrap}>
            <Jump>
              <div className='main-banner'>
                <img alt='' src='/main.png' />
              </div>
            </Jump>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MainSlider