import React from 'react'
import { Fade, Zoom } from 'react-reveal'
import { Button, Col, Container, Row } from 'reactstrap'
import style from '../styles/Services.module.css'



const Services = () => {
    return (
        <section className={style.serviceContainer} id='service'>
            <Container>
                <Zoom cascade>
                    <p className={style.serviceHeader}>Services</p>
                </Zoom>
                <Row>
                    <Col sm={10}>
                        <div className={style.serviceInfo}>
                        <Fade top><h2 className={style.serviceTitle}>Your digital partner to create inspirational design and robust solution for Web, Mobile & Cloud.</h2></Fade>
                        <Fade top><p className={style.serviceDetail}>The top-notch and preeminent Web and Mobile App Development Company of India with its global presence on world, realloc is first-rate choice of the clients worldwide. With sheer customer satisfaction in mind, we are profoundly dedicated to developing highly intriguing apps that strictly meet the business requirements and catering a wide spectrum of projects.</p></Fade>
                        </div>
                    </Col>
                    <Col sm={12} className={style.aboutBtnWraper}>
                    <Fade top><Button className={style.serviceAboutBtn}>About us</Button></Fade>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Services