import React from 'react'
import { Fade, Zoom } from 'react-reveal'
import { Button, Col, Container, Row } from 'reactstrap'
import style from '../styles/Contact.module.css'



const ContactUs = () => {
  return (
    <section className={style.ContactUs} id='contact'>
      <Container>
        <div className={style.mainTitleWrap}>
            <svg className={style.BgText} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1430 254" width="1430" height="254">
                <path id="Path 122" fillRule="evenodd" className={style.ContectBgText} d="m125.9 0.5c54.6 0 98.9 29.2 115.5 79.6h-56.7c-11.6-23.6-32.7-35.2-59.1-35.2-43 0-73.6 31.3-73.6 81.3 0 49.6 30.6 81.3 73.6 81.3 26.4 0 47.5-11.6 59.1-35.6h56.7c-16.6 50.7-60.9 79.6-115.5 79.6-70.7 0-124.6-51.8-124.6-125.3 0-73.6 53.9-125.7 124.6-125.7zm244.3 252c-56.3 0-98.9-39.4-98.9-100.6 0-61.6 44-100.7 100.3-100.7 56.3 0 100.3 39.1 100.3 100.7 0 61.2-45 100.6-101.7 100.6zm0-42.9c26.1 0 51.1-19 51.1-57.7 0-39.1-24.3-57.8-50.4-57.8-26 0-49.6 18.7-49.6 57.8 0 38.7 22.5 57.7 48.9 57.7zm274.6-67.9c0-31.4-17.3-48.3-43.3-48.3-26.8 0-44 16.9-44 48.3v107.7h-49.3v-195h49.3v24.2c12.7-16.5 33.8-27.1 58.8-27.1 45.4 0 77.8 29.6 77.8 83.5v114.4h-49.3zm103.8-46.9h-23.2v-40.4h23.2v-48.3h49.6v48.3h43.7v40.4h-43.7v94.4c0 13 5.3 18.6 20.8 18.6h22.9v41.6h-31c-37.3 0-62.3-15.9-62.3-60.6zm205.2-43.6c31.4 0 52.8 14.8 64.4 31v-27.8h49.7v195h-49.7v-28.6c-11.6 16.9-33.7 31.7-64.7 31.7-49.3 0-88.7-40.5-88.7-101.3 0-60.9 39.4-100 89-100zm12.7 43.3c-26.4 0-51.4 19.7-51.4 56.7 0 36.9 25 58 51.4 58 27.1 0 51.7-20.4 51.7-57.3 0-37-24.6-57.4-51.7-57.4zm233.4-43.3c48.6 0 81.3 25.3 91.9 69h-53.2c-5.6-16.6-18.7-27.1-39.1-27.1-27.4 0-45.7 20.7-45.7 58.8 0 38 18.3 58.4 45.7 58.4 20.4 0 33.1-9.2 39.1-26.8h53.2c-10.6 41.6-43.3 69-91.9 69-56.3 0-96.5-39.4-96.5-100.6 0-61.3 40.2-100.7 96.5-100.7zm135.9 43.6h-23.3v-40.4h23.3v-48.3h49.6v48.3h43.6v40.4h-43.6v94.4c0 13 5.3 18.6 20.8 18.6h22.8v41.6h-30.9c-37.4 0-62.3-15.9-62.3-60.6z"/>
            </svg>
            <Zoom cascade><h2 className={style.mainTitle}>Talk about your project.</h2></Zoom>
            <Zoom cascade><p className={style.subTitle}>Feel free to ask me any question or let's do to talk about our future collaboration.</p></Zoom>
        </div>
        <Row>
            <Col md={5}>
                <div className={style.AddressInfo}>
                    <Fade bottom>
                    <div className={style.contect}>
                        <h3 className={style.contectTitle}>Our contacts</h3>
                        <p className={style.contectText}>Realloc Infotech is well known to craft the most innovative & eye catchy websites. We offer a wide range of customized services in web development, AR-VR Development, Blockchain Development and much more. Our skilled team & our products are engineered to bring growth to your business. We believe in delivering the services without compromising on time and quality.</p>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className={style.contect}>
                        <h3 className={style.contectTitle}>Phone</h3>
                        <a href='tel:919726514624'><p className={style.contectText}>+91 97265 14624</p></a>
                        <a href='tel:917698940092'><p className={style.contectText}>+91 76989 40092</p></a>
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className={style.contect}>
                        <h3 className={style.contectTitle}>Email</h3>
                        <a href='mailto:info@reallocinfotech.com'><p className={style.contectText}>info@reallocinfotech.com</p></a>
                    </div>
                    </Fade>
                </div>
            </Col>
            <Col md={7}>
                <form autoComplete='off'>
                    <Row>
                        <Fade top>
                            <Col md={12}><h3 className={style.contectTitle}>Quick Contact Form</h3></Col>
                        </Fade>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Fade bottom>
                                <div className={style.formControls}>
                                    <input type='text' placeholder='First Name' autoComplete='no'/>
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Fade bottom>
                                <div className={style.formControls}>
                                    <input type='text' placeholder='Last Name' autoComplete='no'/>
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Fade bottom>
                                <div className={style.formControls}>
                                    <input type='text' placeholder='Email' autoComplete='no'/>
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Fade bottom>
                                <div className={style.formControls}>
                                    <input type='text' placeholder='Phone' autoComplete='no'/>
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={12} md={12}>
                            <Fade bottom>
                                <div className={style.formControls}>
                                    <textarea cols={5} rows={5} type='text' placeholder='Your question' autoComplete='no'/>
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={12} md={12} className='mt-3'>
                            <Fade bottom>
                                <Button type='button' className={style.SubmitBtn}>Submit</Button>
                            </Fade>
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactUs
