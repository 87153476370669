import { useState, useEffect } from 'react';

const useMobile = (width) => {

    const [windowSize, setWindowSize] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                var IsMobile = window.innerWidth < width ? true : false
                setWindowSize(IsMobile);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [width]);
    return windowSize;
}
export default useMobile;