export const tabList = [
    {
        id: 1,
        name: 'Food Delivery',
        image: '/service/tab-1.png',
        featureArr: ['Order Food Online', 'Pay Online', 'Reward and Discount Programs', 'Order History', 'Ratings and Reviews'],
        description: 'Online food ordering platforms like disgo follow a simple business model. They act as a common platform between customers and restaurants. Subscribed users search for restaurants, place their order, and pay for it online or opt for COD (cash on delivery).',
    },
    {
        id: 2,
        name: 'E-Commerce',
        image: '/service/tab-2.png',
        featureArr: ['User-friendly product filtering and sorting', 'Detailed product descriptions', 'Shopping cart', 'Shipping options', 'Order summary', 'Secure and easy in-app payments', 'Ordering an invoice and adding company data'],
        description: 'The look and feel of your website design is the first impression of your online store on the global audiences visiting your store. We have a great team of e-commerce web designers to develop scalable e-commerce websites that are intended to offer virtual presence to your brick-and-mortar businesses.',
    },
    {
        id: 3,
        name: 'Healthcare',
        image: '/service/tab-3.png',
        featureArr: ['Doctor profile look up', 'Appointment scheduling', 'Doctor-patient communication', 'Prescription tracking', 'Live Chat', 'Patient data collection'],
        description: "The app needs features that collect and store the patients’ data throughout their whole treatment cycle. This way, the doctors can keep an eye on the patient's progress and treat him accordingly.",
    },
    {
        id: 4,
        name: 'Fitness',
        image: '/service/tab-4.png',
        featureArr: ['Customized Diet Plans', 'Push Notification System & Reminders', 'Payment facility', 'Progress Tracking', 'Online consultation & chat'],
        description: 'There is a great variety of fitness apps and programs aimed to help loose weight, get fit and improve their healthy lifestyle balance. The app has been designed to help people choose the right needs, preferences and training program fitting their limitations.',
    },
    {
        id: 5,
        name: 'Real Estate',
        image: '/service/tab-5.png',
        featureArr: ['User Profiles (User, Sellers & Agents)', 'Filters and Categories', 'Property Profiles', 'Maps', 'Property Cost Calculators'],
        description: 'Real estate webapp provides support for property management and administration through research, filing, reporting, and data entry of real estate information. Whether you are a property owner, tenant, or buyer, we value your business and will provide you with the individual attention and service you deserve.'
    },
    {
        id: 6,
        name: 'Travel',
        image: '/service/tab-6.png',
        featureArr: ['Trip Reviews or Travel Recommendations','Navigation Services','Travel Journal','Weather Forecasts', 'Booking Services'],
        description: 'Travel apps are useful for simply booking a place to rest your head at night. Users want more. One thing most travelers demand from a travel app is a great trip organizer/planner function developed by an experienced enterprise software development team. For an added bonus, give users the ability to post their travel journal directly to their preferred social media platform directly from the app. we  build all such features for your business.',
    },
    {
        id: 7,
        name: 'Sports',
        image: '/service/tab-7.png',
        featureArr: ['social media integration','news feed','sportspeople or sports teams catalog','payment gateway'],
        description: 'One of the major reasons people install sports apps is to know the real-time scores of the sport. Best sports apps come equipped with real-time score tracking. These sports mobile apps are quite appealing to various users. Thus it is meaningful to make these apps as interactive as possible. A feature of social media integration is required for sports mobile app development.',
    },
    {
        id: 8,
        name: 'Business',
        image: '/service/tab-8.png',
        featureArr: ['Provide more value to your customers','Build a stronger brand','Connect with your customers fast and easy','Improve customer loyalty','Reach a higher customer engagement level','Find valuable consumer insights','Have an avenue for customer feedback'],
        description: 'Building a small business website is important to inform your audience, explain your value proposition, boost brand recognition and drive sales. Consumers turn to the internet for everything from product research to location and operating hours. Even just a simple, well-designed website can give you an edge in your field.',
    },
    {
        id: 9,
        name: 'Education',
        image: '/service/tab-9.png',
        featureArr: ['Live Tutorials and Sessions','Robust database & Sharing','Excellent UX/UI','Video-based Content','Interactive Content','Testing Knowledge','Multilingual addressed'],
        description: 'Remote learning has touched every age group, especially during the past two years. Technology has reformulated the education system, giving people greater access to facilities for learning. Educational Apps cater to a varied age group – toddlers, kids, teenagers, professionals seeking new learning, and specialists trying to gain a knowledge edge.',
    },
    {
        id: 10,
        name: 'Fintech',
        image: '/service/tab-11.png',
        featureArr: ['Two-factor authentication','Timed logout','Investment solutions','Digital wallets'],
        description: 'We reinvent the way people pay, buy, and invest. We bring to the table our extensive FinTech domain knowledge and best engineers to help you create next-gen financial solutions. We Build FinTech Apps that Help Your Business Come Out Strongly through All Challenges',
    },
    {
        id: 11,
        name: 'Entertainment',
        image: '/service/tab-10.png',
        featureArr: ['Audio & video streaming.','Real time content update.','Text and GPS based facilities.','In-app purchases.','Social media integration.','Localization'],
        description: 'We offer the Most Innovative of Next-Gen Media & Entertainment Industry App Development. Our team is skilled in developing entertainment apps that not just keep the users engaged but also make them brand evangelists.',
    },
]