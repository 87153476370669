import React from 'react'
import { Zoom } from 'react-reveal'
import { Col, Container, Row } from 'reactstrap'
import style from '../styles/OurBlogs.module.css'

const OurBlogs = () => {
  return (
    <section className={style.OurBlogs} id='blogs'>
      <Container>
        <div className={style.MainTitle}>
            <h4 className={style.Title}>OUR BLOGS</h4>
            <div className={style.ShowMore}><h4>VIEWALL</h4></div>
        </div>
        <Row>
            <Col xs={12} sm={6} md={6} className={style.BlogPostWrapLeft} style={{paddingTop:110}}>
                <Zoom delay={100}>
                    <div className={style.BlogPost}>
                        <img alt='' src='/blogs/blog-1.jpg' />
                        <h4>NFT App</h4>
                        <p>Ui/Ux design, Mobile App Development.</p>
                    </div>
                </Zoom>
            </Col>
            <Col xs={12} sm={6} md={6} className={style.BlogPostWrap}>
                <Zoom delay={350}>
                    <div className={style.BlogPost}>
                        <img alt='' src='/blogs/blog-2.jpg' />
                        <h4>Digital Wallet App</h4>
                        <p>Ui/Ux design, Mobile App Development.</p>
                    </div>
                </Zoom>
            </Col>
            <Col xs={12} sm={6} md={6} className={style.BlogPostWrapLeft}>
                <Zoom delay={600}>
                    <div className={style.BlogPost}>
                        <img alt='' src='/blogs/blog-3.jpg' />
                        <h4>E-commerce App</h4>
                        <p>Ui/Ux design, Mobile App Development.</p>
                    </div>
                </Zoom>
            </Col>
            <Col xs={12} sm={6} md={6} className={style.BlogPostWrap}>
                <Zoom delay={950}>
                    <div className={style.BlogPost}>
                        <img alt='' src='/blogs/blog-4.jpg' />
                        <h4>Weather App</h4>
                        <p>Ui/Ux design, Mobile App Development.</p>
                    </div>
                </Zoom>
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OurBlogs
