/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import style from '../../styles/header.module.css'
import Reveal from 'react-reveal/Reveal';
import Roll from 'react-reveal/Roll';
import useMobile from './useMediaQuery'
import { Zoom } from 'react-reveal'

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [isMobile, setMobile] = useState()
  const checkMobileView = useMobile(992);

  useEffect(() => {
    setMobile(checkMobileView)
  }, [checkMobileView])

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.getElementById('header');
    const scrollTop = window.scrollY;
    if (scrollTop >= 250) {
      document.querySelector('.layout-content').style.marginTop = header.clientHeight + 'px';
      header.classList.add('sticky') 
    } else {
      document.querySelector('.layout-content').style.marginTop = '0px';
      header.classList.remove('sticky')
    }
  };

  return (
    <header className={style.header} id='header'>
      <Reveal effect="fadeInDown">
        <Container className={style.container}>
          <Row className='align-items-center'>
            <Col xs={8} sm={4} md={3} lg={3} xl={3} xxl={3}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href='#'><img src='/logo.svg' alt='realloc' width={235} /></a>
            </Col>
            <Col xs={4} sm={8} md={9} lg={9} xl={9} xxl={9}>
              <Button className={`${style.MenuIcon} ${showMenu ? 'menu-open' : ''}`} onClick={() => setShowMenu(!showMenu)}>
                <span></span>
                <span></span>
                <span></span>
              </Button>
              <nav className={`${style.navbarRight}  ${showMenu ? 'showMenu' : 'hideMenu'}`}>
                {isMobile ?
                  <Roll left cascade when={showMenu}>
                    <ul>
                      <li><a href='#service'>Service</a></li>
                      <li><a href='#solution'>Solution</a></li>
                      <li><a href='#our-work'>Our Works</a></li>
                      <li><a href='#clients'>Our Technology</a></li>
                      <li><a href='#contact'>Contact Us</a></li>
                      <li><a href='#blogs'>Blogs</a></li>
                    </ul>
                  </Roll>
                  :
                  <Zoom cascade>
                    <ul>
                      <li><a href='#service'>Service</a></li>
                      <li><a href='#solution'>Solution</a></li>
                      <li><a href='#our-work'>Our Works</a></li>
                      <li><a href='#clients'>Our Technology</a></li>
                      <li><a href='#contact'>Contact Us</a></li>
                      <li><a href='#blogs'>Blogs</a></li>
                    </ul>
                  </Zoom>
                }<Zoom cascade>
                  <Button type='button' className={style.ButtonPrimery}>Contact Us</Button>
                </Zoom>
              </nav>
            </Col>
          </Row>
        </Container>
      </Reveal>
    </header>
  )
}

export default Header