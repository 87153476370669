import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import style from '../styles/GetInTouch.module.css'
import Fade from 'react-reveal/Fade';

const GetInTouch = () => {
    return (
        <section className={style.GetInTouch}>
            <Container>
                <div className={style.GetInTouchBlock}>
                    <Row>
                        <Col xs={12} sm={12} md={4} className={style.Info}>
                            <Fade top deley={200}>
                                <div>
                                <h3 className={style.GetInTouchTitle}>Looking for<br /> career opportunity?</h3>
                                </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={6} md={4} className={style.Info}>
                            <Fade top deley={200}>
                                <a href='mailto:info@reallocinfotech.com'>
                                    <label className={style.GetInTouchLabel}><img src='/email.png' alt='' width={22} height={22} />&nbsp;Email</label>
                                    <h4 className={style.GetInTouchText}>info@reallocinfotech.com</h4>
                                </a>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={6} md={4} className={style.Info}>
                            <Fade top deley={200}>
                                <a href='tel:97265 14624'>
                                <label className={style.GetInTouchLabel}><img src='/call.png' alt='' width={22} height={22} />&nbsp;Call</label>
                                <h4 className={style.GetInTouchText}>+91 97265 14624</h4>
                                </a>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default GetInTouch
